<template>
  <view-item title="喷涂工单">
    <template #operation>
      <en-button type="primary" @click="operation.add.click">新建喷涂工单</en-button>
      <en-button type="primary">业务统计</en-button>
      <button-ajax v-if="form.data.id" :method="operation.save.click">保存</button-ajax>
      <button-ajax
        v-if="form.data.id && ['unpainted', 'painted', 'return'].includes(form.status) && store.user.tenant?.id !== 'ENOCH'"
        :method="operation.commit.click"
      >
        上传工单
      </button-ajax>
      <button-delete
        v-if="form.data.id && form.status === 'return' && store.user.tenant?.id !== 'ENOCH'"
        type="danger"
        :link="false"
        :method="operation.delete.click"
      >
        删除
      </button-delete>
      <button-delete
        v-if="form.data.id && form.status === 'return' && store.user.tenant?.id !== 'ENOCH'"
        type="danger"
        :link="false"
        content="一旦作废不能修复，请谨慎！"
        :method="operation.discard.click"
      >
        作废
      </button-delete>
      <en-dropdown v-if="form.data.id" @command="operation.option.command">
        <en-button type="primary">操作</en-button>
        <template #dropdown>
          <en-dropdown-item command="detail">编辑工单</en-dropdown-item>
          <en-dropdown-item command="qrcode">质保二维码</en-dropdown-item>
          <en-dropdown-item v-if="store.user.tenant?.id !== 'ENOCH' && ['unpainted', 'painted'].includes(form.status)" command="apply-warranty">
            联保申请
          </en-dropdown-item>
          <en-dropdown-item
            v-if="store.user.tenant?.id === 'ENOCH' && store.user?.id === form.data.tenant?.salesman?.id && form.warrantyApplicationSurfaces.length"
            command="audit-warranty"
          >
            审核联保申请
          </en-dropdown-item>
          <en-dropdown-item command="print-formula">打印配方</en-dropdown-item>
          <en-dropdown-item v-if="['unpainted', 'painted'].includes(form.status) && store.user.tenant?.id !== 'ENOCH'" command="merge">
            合并调漆
          </en-dropdown-item>
          <en-dropdown-item
            v-if="['unpainted', 'painted', 'return'].includes(form.status) && store.user.tenant?.id !== 'ENOCH' && form.data.formula?.id"
            command="switch-procedure"
          >
            切换工序
          </en-dropdown-item>
          <en-dropdown-item command="reject">工单退回</en-dropdown-item>
          <en-dropdown-item command="logs">单据历史</en-dropdown-item>
          <en-dropdown-item command="single">废弃配方</en-dropdown-item>
        </template>
      </en-dropdown>
    </template>

    <template #manifest>
      <table-manifest
        code="SPRWOSDBFD"
        :ajax="{ action: 'GET /enospray/workorder/query' }"
        :props="{ start: 'preparedStartDate', end: 'preparedEndDate' }"
        @expand-click="dialog.visible = true"
        @row-click="manifest.row.click"
      >
        <template #STATUS="{ row }: { row: EnospraySprayDefinitions['WorkOrderQueryDto'] }">
          <en-tag>{{ row.status?.message }}</en-tag>
        </template>

        <template #form="{ data }">
          <en-form-item v-if="store.user?.tenant?.id === 'ENOCH'" label="网点">
            <select-maintain
              v-model="data.tenantId"
              :ajax="{ action: 'GET /enospray/common/tenant', params: (params, shortName) => (params.payload = { shortName }) }"
              :props="{ label: 'shortName', value: 'id' }"
              remote
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="是否质保">
            <select-maintain
              v-model="data.warranty"
              :ajax="{ action: 'GET /enospray/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
              :props="{ label: 'message', value: 'code' }"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="是否回访">
            <select-maintain
              v-model="data.returnVisited"
              :ajax="{ action: 'GET /enospray/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
              :props="{ label: 'message', value: 'code' }"
              class="w-full"
            ></select-maintain>
          </en-form-item>
        </template>
      </table-manifest>
    </template>

    <view-item-content :disabled="!form.data.id">
      <en-card v-loading="form.loading">
        <en-collapse>
          <en-collapse-item>
            <template #title>
              <div class="flex items-center gap-6">
                <span>{{ form.data.plateNo }}</span>
                <span>{{ form.data.cellphone }}</span>
                <span>单号：{{ form.data.serialNo }}</span>
                <en-tag>{{ form.data.status?.message }}</en-tag>
                <span>{{ form.data.vehicleSpec?.join('/') }}</span>
                <span>{{ form.data.paintType?.message }}/{{ form.data.chargingStandard?.message }}</span>
                <span>是否返工：{{ form.data.rework?.message }}</span>
                <span>{{ form.data.workingTeam || '无' }}/{{ form.data.assignees?.join(',') || '无' }}</span>
              </div>
            </template>
            <div class="grid grid-cols-3">
              <span>网点简称：{{ form.data.tenant?.shortName }}</span>
              <span>维修单：{{ form.data.serviceSerialNo }}({{ form.data.serviceStatus?.message }})</span>
              <span>制单人：{{ form.data.preparedBy }}</span>
              <span>工单时间： {{ formatDate(form.data.preparedDatetime) }}</span>
              <span>喷涂时间：{{ formatDate(form.data.serviceExpectedCompletionDatetime) }}</span>
              <span>提交时间： {{ formatDate(form.data.preparedDatetime) }}</span>
              <span>备注： {{ form.data.comment }}</span>
            </div>
          </en-collapse-item>
        </en-collapse>
      </en-card>

      <en-card class="flex-1 overflow-auto" body-class="h-full">
        <flex-box>
          <template #default="{ height }">
            <en-tabs v-model="tabs.active">
              <template #operation>
                <en-button type="primary" @click="tabs.surface.click">选择喷涂钣面</en-button>
                <en-button type="primary" @click="tabs.autoweighing.click">保存配色</en-button>
                <en-dropdown
                  v-if="form.data.id && form.status === 'unpainted' && !form.data.paints?.length"
                  split-button
                  type="primary"
                  @click="tabs.formula.click"
                  @command="tabs.formula.command"
                >
                  <span>调用配方</span>
                  <template #dropdown>
                    <en-dropdown-item command="single">单工序</en-dropdown-item>
                    <en-dropdown-item command="second">双工序</en-dropdown-item>
                    <en-dropdown-item command="three">三工序</en-dropdown-item>
                    <en-dropdown-item command="history">历史配方</en-dropdown-item>
                  </template>
                </en-dropdown>
              </template>

              <en-tab-pane
                label="维修"
                name="maintenance"
                :badge="form.data.surfaces?.length"
                :style="{ height: height - 55 + 'px', overflow: 'auto' }"
              >
                <flex-box>
                  <template #default="{ height }">
                    <en-table :data="form.data.surfaces" :height="height" :loading="form.loading">
                      <en-table-column label="序号" type="index" width="80"></en-table-column>
                      <en-table-column label="项目" prop="surface.message">
                        <template #default="{ row }: { row: EnospraySprayDefinitions['WorkOrderSurfaceDto'] }">
                          <select-maintain
                            v-if="!row.surface?.code"
                            v-model="row.surface"
                            :ajax="{
                              action: 'GET /enospray/common/lookup/:lookupType',
                              params: (params) => (params.paths = ['SURFACE']),
                              convert: (data) => data.filter((item) => item.code.startsWith('OTH_'))
                            }"
                            :props="{
                              label: 'message',
                              value: '',
                              disabled: (option) => Boolean(form.data.surfaces?.find((item) => item.surface?.code === option.code))
                            }"
                            value-key="code"
                            :disabled="form.disabled"
                            @change="form.surfaces.surface.change(row)"
                          ></select-maintain>
                          <span v-else>{{ row.surface?.message }}</span>
                        </template>
                      </en-table-column>
                      <en-table-column label="面积" prop="square">
                        <template #default="{ row }: { row: EnospraySprayDefinitions['WorkOrderSurfaceDto'] }">
                          <en-input-number
                            v-if="Boolean(row.surface?.code.startsWith('OTH'))"
                            :model-value="calculator.mul(row.count, row.surface?.square)"
                            step-strictly
                            controls
                            controls-position="right"
                            :min="0.2"
                            :step="0.2"
                            :disabled="form.disabled || !store.accessRightsHash.WORK_ORDER_TENANT_CREATE_OR_UPDATE"
                            class="w-full"
                            @change="form.surfaces.size.change(row, $event)"
                          ></en-input-number>
                          <span v-else>{{ `${calculator.mul(row.count, row.surface?.square)}m²` }}</span>
                        </template>
                      </en-table-column>
                      <en-table-column label="价格" prop="price">
                        <template #default="{ row }: { row: EnospraySprayDefinitions['WorkOrderSurfaceDto'] }">
                          <price-show :data="row.price"></price-show>
                        </template>
                      </en-table-column>
                      <en-table-column label="是否质保">
                        <template #default="{ row }: { row: EnospraySprayDefinitions['WorkOrderSurfaceDto'] }">
                          <select-maintain
                            v-model="row.warrantied"
                            :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
                            :props="{ label: 'message', value: '' }"
                            value-key="code"
                            :disabled="
                              !store.accessRightsHash.WORK_ORDER_TENANT_CREATE_OR_UPDATE ||
                              ['settlemented', 'finish'].includes(form.status) ||
                              (row.surface && ['S', 'B'].includes(row.surface.code.split('_')[1])) ||
                              row.jointWarrantyApplicationStatus?.code === 'A'
                            "
                          ></select-maintain>
                        </template>
                      </en-table-column>
                      <en-table-column label="是否联保" prop="jointWarrantyApplicationStatus.message"></en-table-column>
                      <en-table-column label="原网点" prop="jointWarrantySurface.workOrder.tenant.shortName"></en-table-column>
                      <en-table-column label="备注">
                        <template #default="{ row }: { row: EnospraySprayDefinitions['WorkOrderSurfaceDto'] }">
                          <en-input
                            v-model="row.comment"
                            :disabled="!store.accessRightsHash.WORK_ORDER_TENANT_CREATE_OR_UPDATE || ['settlemented', 'finish'].includes(form.status)"
                          ></en-input>
                        </template>
                      </en-table-column>
                      <en-table-column label="操作">
                        <template #default="{ $index, row }: { $index: number; row: EnospraySprayDefinitions['WorkOrderSurfaceDto'] }">
                          <en-button
                            type="primary"
                            link
                            :disabled="form.surfaces.operation.delete.disabled(row)"
                            @click="form.surfaces.operation.delete.click($index)"
                          >
                            删除
                          </en-button>
                        </template>
                      </en-table-column>
                    </en-table>
                  </template>
                </flex-box>

                <div>
                  <en-button
                    type="primary"
                    text
                    :disabled="!store.accessRightsHash.WORK_ORDER_TENANT_CREATE_OR_UPDATE || ['settlemented', 'finish'].includes(form.status)"
                    @click="form.surfaces.special.add.click"
                  >
                    特殊喷漆
                  </en-button>
                </div>
              </en-tab-pane>

              <en-tab-pane label="色母" name="goods" :badge="form.data.paints?.length">
                <en-scrollbar :height="height - 55">
                  <en-form
                    :model="form.data"
                    :rules="form.rules"
                    label-width="100"
                    label-position="right"
                    :ref="setRef('form')"
                    class="grid grid-cols-4 gap-x-6"
                  >
                    <en-form-item label="色号">
                      <en-input v-model="form.data.colorCode" :disabled="form.disabled"></en-input>
                    </en-form-item>
                    <en-form-item label="颜色名称">
                      <en-input v-model="form.data.colorName" :disabled="form.disabled"></en-input>
                    </en-form-item>
                    <en-form-item label="年份">
                      <en-date-picker v-model="form.data.year" type="year" :disabled="form.disabled"></en-date-picker>
                    </en-form-item>
                    <en-form-item label="色系">
                      <select-maintain
                        v-model="form.data.colorFamily"
                        :ajax="{
                          action: 'GET /enospray/common/lookup/:lookupType',
                          params: (params) => (params.paths = ['COLFML']),
                          convert: (data) => data.filter((item) => (form.data.paintType?.code === 'BASF_W' ? item.bsf : item.ppg))
                        }"
                        :props="{ label: 'message', value: '' }"
                        value-key="code"
                        :disabled="form.disabled"
                        class="w-full"
                      ></select-maintain>
                    </en-form-item>
                    <en-form-item label="颜色效果">
                      <select-maintain
                        v-model="form.data.colorEffect"
                        :ajax="{ action: 'GET /enospray/common/lookup/:lookupType', params: (params) => (params.paths = ['CLREFFECT']) }"
                        :props="{ label: 'message', value: '' }"
                        value-key="code"
                        :disabled="form.disabled"
                        class="w-full"
                      ></select-maintain>
                    </en-form-item>
                    <en-form-item label="派工班组">
                      <select-maintain
                        v-model="form.data.workingTeam"
                        :ajax="{
                          action: 'GET /enocloud/common/workingteam',
                          convert: (data) => data.filter((item) => item.status?.code === 'A')
                        }"
                        :props="{ label: 'name', value: 'name' }"
                        @change="form.workingTeam.change"
                        :disabled="form.disabled"
                        class="w-full"
                      ></select-maintain>
                    </en-form-item>
                    <en-form-item label="派工技师">
                      <select-maintain
                        v-model="form.data.assignees"
                        :options="form.assignees.data"
                        :props="{ label: 'user.name', value: 'user.name' }"
                        :disabled="form.disabled"
                      ></select-maintain>
                    </en-form-item>
                    <en-form-item label="色母总用量">
                      <en-input :model-value="form.summary.weight" disabled></en-input>
                    </en-form-item>
                  </en-form>

                  <div class="bg-[#F5FBFF] rounded p-4 mb-4 flex items-center justify-between text-xs">
                    <div class="flex items-center gap-4">
                      <img v-if="form.paints.scanner.active" src="@spray/assets/scanner-active.png" class="w-15 h-15" />
                      <img v-else src="@spray/assets/scanner.png" class="w-15 h-15" />
                      <span v-if="form.paints.scanner.active">当前色母：{{ form.paints.scanner.code || '未扫码' }}</span>
                      <en-button v-else type="primary" @click="form.paints.scanner.open">点击连接</en-button>
                    </div>
                    <div class="flex items-center gap-4">
                      <img v-if="form.paints.scale.active" src="@spray/assets/scale-active.png" class="w-15 h-15" />
                      <img v-else src="@spray/assets/scale.png" class="w-15 h-15" />
                      <span v-if="form.paints.scale.active">重量：{{ form.paints.scale.code || '无读数' }}</span>
                      <en-button v-else type="primary" @click="form.paints.scale.open">点击连接</en-button>
                    </div>
                    <div class="flex items-center">
                      <img src="@spray/assets/arrow.png" class="w-30" />
                    </div>
                    <div class="flex flex-col gap-1 items-center">
                      <img src="@spray/assets/refresh.png" class="w-8 h-8" />
                      <span>重复操作</span>
                      <span>直至完成所有调漆添加</span>
                    </div>
                    <div class="flex items-center">
                      <img src="@spray/assets/arrow.png" class="w-30" />
                    </div>
                    <div class="flex flex-col gap-1 items-center">
                      <en-button type="primary">开始微调</en-button>
                      <span>若需要微调</span>
                      <span>请点击开始微调进行操作 </span>
                    </div>
                    <div class="flex items-center">
                      <en-button type="primary">暂停</en-button>
                    </div>
                  </div>

                  <div v-if="form.data.procedureType?.code === '3'">
                    <en-radio-group v-model="form.colorLayer.code" class="p-5">
                      <en-radio label="C">色漆层</en-radio>
                      <en-radio label="P">珍珠层</en-radio>
                    </en-radio-group>
                  </div>

                  <en-collapse v-model="collapse.actives">
                    <en-collapse-item title="标准色" name="standard">
                      <en-table :data="form.standardPaints" :loading="form.loading">
                        <en-table-column label="色母编号">
                          <template #default="{ row }: { row: EnospraySprayDefinitions['WorkOrderPaintDto'] }">
                            <select-maintain
                              v-model="row.goods"
                              :ajax="{
                                action: 'GET /enospray/common/goods',
                                params: (params, value) => {
                                  params.payload = { goodsTypeCategoryCode: 'PAINT', paintBrandCode: form.data.paintType?.brand.code, name: value }
                                },
                                convert(data) {
                                  return data.map((item) => {
                                    item.workshopGoodsSpecification = {
                                      ...item.workshopGoodsSpecification,
                                      goods: item.storageGoodsSpecification?.goods
                                    }
                                    return item
                                  })
                                }
                              }"
                              :props="{
                                label: 'workshopGoodsSpecification.goods.serialNo',
                                value: '',
                                disabled: (option) => {
                                  return Boolean(
                                    form.data.paints?.find(
                                      (item) =>
                                        item.status?.code === 'A' && item.colorLayer?.code === form.colorLayer.code && item.goods?.id === option.id
                                    )
                                  )
                                }
                              }"
                              value-key="id"
                              remote
                              :disabled="form.disabled"
                              @change="form.paints.goods.change(row)"
                            >
                              <template #default="{ option }: { option: EnosprayCommonDefinitions['SprayGoodsDto'] }">
                                <span style="float: left">{{ option.storageGoodsSpecification?.goods?.serialNo }}</span>
                                <span style="float: right; color: #8492a6; font-size: 13px">{{ option.storageGoodsSpecification?.goods?.name }}</span>
                              </template>
                            </select-maintain>
                          </template>
                        </en-table-column>
                        <en-table-column label="色母名称" prop="goods.workshopGoodsSpecification.goods.name"></en-table-column>
                        <en-table-column label="标准用量(g)" v-if="form.data.formula?.id">
                          <template #default="{ row }: { row: EnospraySprayDefinitions['WorkOrderPaintDto'] }">
                            {{ formatNumber(0) }}
                          </template>
                        </en-table-column>
                        <en-table-column label="预计用量(g)" v-if="form.data.formula?.id">
                          <template #default="{ row }: { row: EnospraySprayDefinitions['WorkOrderPaintDto'] }">
                            <div class="flex items-center justify-center">
                              <span>{{ formatNumber(0, 1) }}</span>
                            </div>
                          </template>
                        </en-table-column>
                        <en-table-column label="调漆用量(g)">
                          <template #default="{ row }: { row: EnospraySprayDefinitions['WorkOrderPaintDto'] }">
                            <en-input-number v-model="row.weight" :disabled="form.paints.weight.disabled()"></en-input-number>
                          </template>
                        </en-table-column>
                        <en-table-column label="微调前重量(g)" prop="beforeAdjustingWeight"></en-table-column>
                        <en-table-column label="微调后重量(g)" prop="afterAdjustingWeight">
                          <template #default="{ row }: { row: EnospraySprayDefinitions['WorkOrderPaintDto'] }">
                            <en-input-number
                              :model-value="0"
                              :max="row.beforeAdjustingWeight"
                              :mix="0"
                              :disabled="form.paints.weight.disabled()"
                              @change="form.paints.afterAdjustingWeight.change(row, $event)"
                            ></en-input-number>
                          </template>
                        </en-table-column>
                        <en-table-column label="操作" width="80">
                          <template #default="{ row, $index }: { row: EnospraySprayDefinitions['WorkOrderPaintDto']; $index: number }">
                            <button-delete :disabled="!!row.id" :method="form.paints.delete.click" :params="$index">清空</button-delete>
                          </template>
                        </en-table-column>
                      </en-table>
                      <en-button v-if="store.user.sprayTenant?.preventManualUpdate?.code === 'N'" type="primary" link @click="form.paints.add.click">
                        添加色母
                      </en-button>
                    </en-collapse-item>

                    <en-collapse-item title="废弃" name="obsolet">
                      <en-table :data="form.obsoletPaints" :loading="form.loading">
                        <en-table-column label="色母编号" prop="goods.storageGoodsSpecification.goods.serialNo"> </en-table-column>
                        <en-table-column label="色母名称" prop="goods.storageGoodsSpecification.goods.name"></en-table-column>
                        <en-table-column label="调漆用量(g)" prop="weight"></en-table-column>
                      </en-table>
                    </en-collapse-item>
                  </en-collapse>
                </en-scrollbar>
              </en-tab-pane>
            </en-tabs>
          </template>
        </flex-box>
      </en-card>
    </view-item-content>
  </view-item>

  <workorder-dialog v-model="dialog.visible"></workorder-dialog>

  <workorder-detail v-model="detail.visible" :data="form.data" @success="detail.success"></workorder-detail>

  <spray-surface-selection
    v-model="surface.visible"
    :data="surface.data"
    type="workorder-spray"
    @selection-change="surface.selection.change"
    @confirm="surface.confirm"
  ></spray-surface-selection>

  <formula-dialog v-model="formula.visible" :filters="formula.filters"></formula-dialog>

  <workorder-warranty-apply v-model="warrantyApply.visible" :workorder-id="form.data.id" :method="form.get"></workorder-warranty-apply>

  <workorder-warranty-audit v-model="warrantyAudit.visible" :workorder-id="form.data.id" :method="form.get"></workorder-warranty-audit>

  <switch-procedure v-model="procedure.visible" @change="procedure.change"></switch-procedure>

  <workorder-spray-merge v-model="merge.visible" :data="form.data"></workorder-spray-merge>

  <workorder-logs v-model="logs.visible" :data="form.data"></workorder-logs>
</template>

<script lang="ts">
import { h } from 'vue'
import { calculator, formatMoney } from '@enocloud/utils'
import { Formula, print } from '@enocloud/print'
import { EnButton, EnMessage, EnMessageBox } from '@enocloud/components'
import { SpraySurfaceSelection } from '@enocloud/business'

import WorkorderDialog from '@spray/components/workorder-dialog.vue'
import FormulaDialog from '@spray/components/formula-dialog.vue'
import WorkorderWarrantyApply from '@spray/components/workorder-warranty-apply.vue'
import WorkorderWarrantyAudit from '@spray/components/workorder-warranty-audit.vue'
import WorkorderDetail from '@spray/components/workorder-detail.vue'
import WorkorderLogs from '@spray/components/workorder-logs.vue'
import SwitchProcedure from '@spray/components/switch-procedure.vue'
import WorkorderSprayMerge from '@spray/components/workorder-spray-merge.vue'

import { getScannerConnection, ScannerEventType, type IScannerConnection } from '@spray/driver/scanner'
import { getScaleConnection, ScaleEventType, type IScaleConnection } from '@spray/driver/scale'

const formDataValid = (data: EnospraySprayDefinitions['WorkOrderDto']) => {
  return new Promise((resolve) => {
    try {
      const { colorCode, colorName, year, colorFamily, colorEffect } = data
      if (!colorCode) throw new Error('请填写色号')
      if (!colorName) throw new Error('请填写颜色名称')
      if (!year) throw new Error('请填写年份')
      if (!colorFamily?.code) throw new Error('请填写色系')
      if (!colorEffect?.code) throw new Error('请选择颜色效果')
      resolve(true)
    } catch (err: any) {
      EnMessage.warning(err.message)
      resolve(false)
    }
  })
}

let scannerTimer: NodeJS.Timeout | null
let scaleTimer: NodeJS.Timeout | null
let duration = 1500

export default factory({
  components: {
    SpraySurfaceSelection,
    WorkorderDialog,
    FormulaDialog,
    WorkorderWarrantyApply,
    WorkorderWarrantyAudit,
    WorkorderDetail,
    SwitchProcedure,
    WorkorderLogs,
    WorkorderSprayMerge,
    PriceShow: factory({
      props: { data: Number as PropType<number> },
      data: () => ({ display: false }),
      render(h) {
        return h(
          'div',
          {
            style: { display: 'flex', alignItems: 'center', marginRight: '4px' }
          },
          [
            this.display && h('span', formatMoney(this.data)),
            h(
              EnButton,
              {
                type: 'primary',
                text: true,
                onClick: () => {
                  this.display = !this.display
                }
              },
              () => (this.display ? '隐藏' : '查看')
            )
          ]
        )
      }
    })
  },

  config: {
    children: {
      operation: {
        children: {
          add: {
            click() {
              this.form.init()
              this.detail.visible = true
            }
          },
          save: {
            async click() {
              await this.form[this.form.data.id ? 'update' : 'submit']()
              return this.form.get()
            }
          },
          commit: {
            async click() {
              await this.form.commit()
              return this.form.get()
            }
          },
          delete: {
            async click() {
              await this.form.delete()
              this.form.init()
            }
          },
          discard: {
            async click() {
              await this.form.discard()
              return this.form.get()
            }
          },
          option: {
            async command(option: string) {
              const { paints, procedureType, colorCode, colorName, vehicleSpec } = this.form.data

              switch (option) {
                case 'detail':
                  this.detail.visible = true
                  break
                case 'qrcode':
                  const res = await this.form.qrcode()
                  EnMessageBox({
                    title: this.form.data.plateNo,
                    center: true,
                    showConfirmButton: false,
                    message: h('img', { src: res.data[0], width: 300, height: 300, class: 'm-auto' })
                  })
                  break
                case 'apply-warranty':
                  this.warrantyApply.visible = true
                  break
                case 'audit-warranty':
                  this.warrantyAudit.visible = true
                  break
                case 'print-formula':
                  print(Formula, {
                    props: {
                      data: {
                        colorCode,
                        colorName,
                        vehicleSpec,
                        colors: paints.filter((p) => p.colorLayer?.code === 'C' && p.status?.code === 'A'),
                        pearls: paints.filter((p) => p.colorLayer?.code === 'P' && p.status?.code === 'A')
                      }
                    }
                  })
                  break
                case 'merge':
                  if (this.form.paints.scope.value !== 'weight') return EnMessage.warning('请先完成微调')
                  if (!paints.filter((item) => item.status?.code === 'A' && item.weight > 0).length) return EnMessage.warning('色母不可为空')
                  if (procedureType.code === '3') {
                    const paints_c = paints.filter((item) => item.status?.code !== 'O' && item.colorLayer?.code === 'C')
                    const paints_p = paints.filter((item) => item.status?.code !== 'O' && item.colorLayer?.code === 'P')
                    if (!paints_c.length) return EnMessage.warning('色漆层未调漆')
                    if (!paints_p.length) return EnMessage.warning('珍珠层未调漆')
                  }
                  const valid = await formDataValid(this.form.data)
                  if (!valid) return

                  this.form.paints.active.data = null
                  this.merge.visible = true
                  break
                case 'switch-procedure':
                  this.procedure.visible = true
                  break
                case 'reject':
                  const action = await EnMessageBox({
                    title: '提示',
                    message: h('p', null, [h('span', { style: 'color:  #f4523b' }, `确认将此工单退回吗？`)]),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    distinguishCancelAndClose: true
                  })
                  if (action === 'confirm') {
                    await this.form.reject()
                  }
                  break
                case 'logs':
                  this.logs.visible = true
                  break
              }
            }
          }
        }
      },
      manifest: {
        row: {
          click(row: EnospraySprayDefinitions['WorkOrderQueryDto']) {
            this.form.init()
            this.form.data.id = row.id
            this.form.get()
          }
        }
      },
      tabs: {
        active: 'maintenance',
        children: {
          formula: {
            click() {
              const { vehicleSpec, colorCode, colorName, paintType } = this.form.data
              this.formula.filters = {}
              this.formula.filters.brand = vehicleSpec[0]
              this.formula.filters.colorCode = colorCode
              this.formula.filters.colorName = colorName
              this.formula.filters.paintTypeCode = paintType?.code
              this.formula.visible = true
            },
            command(option: string) {}
          },
          surface: {
            click() {
              this.surface.data = [...this.form.data.surfaces].filter((item) => !!item.surface).map((item) => item.surface!)
              this.surface.visible = true
            }
          },
          autoweighing: {
            async click() {
              if (this.form.paints.scope.value !== 'weight') return EnMessage.warning('请先完成微调')
              const { paints, procedureType } = this.form.data
              if (!paints.filter((item) => item.status?.code === 'A' && item.weight > 0).length) return EnMessage.warning('色母不可为空')
              if (procedureType.code === '3') {
                const paints_c = paints.filter((item) => item.status?.code !== 'O' && item.colorLayer?.code === 'C')
                const paints_p = paints.filter((item) => item.status?.code !== 'O' && item.colorLayer?.code === 'P').filter((m) => m.weight)
                if (!paints_c.length) return EnMessage.warning('色漆层未调漆')
                if (!paints_p.length) return EnMessage.warning('珍珠层未调漆')
              }
              const valid = await formDataValid(this.form.data)
              if (!valid) return

              if (this.form.status === 'unpainted') {
                const formula = Object.assign({}, this.form.data.formula)
                const validPaints = paints.filter((item) => item.status?.code === 'A')
                const invalidPaints = paints.filter((item) => item.status?.code === 'O')

                this.form.data.paints = invalidPaints
                try {
                  await this.form.submit()
                  await this.form.get()
                  await this.form.obsolete()
                  await this.form.get()
                } finally {
                  this.form.data.formula = formula
                  this.form.data.paints.push(...validPaints)
                }

                await this.form.submit()
                await this.form.get()
              } else {
                await this.form.submit()
              }
              await this.form.autoWeigh()
              this.form.get()
              EnMessage.success('配色保存成功')
            }
          }
        }
      },
      collapse: {
        actives: ['standard']
      },
      form: {
        rules: {},
        ajax: {
          get: {
            action: 'GET /enospray/workorder/:workOrderId',
            data: 'object',
            init: true,
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'POST /enospray/workorder',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          update: {
            action: 'PUT /enospray/workorder',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          commit: {
            action: 'POST /enospray/workorder/:workOrderId/commit',
            loading: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          delete: {
            action: 'DELETE /enospray/workorder/:workOrderId',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          discard: {
            action: 'POST /enospray/workorder/:workOrderId/discard',
            loading: true,
            params(params) {
              params.payload = this.form.data
              params.paths = [this.form.data.id]
            }
          },
          qrcode: {
            action: 'GET /enospray/workorder/:workOrderId/qrcode',
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          reject: {
            action: 'POST /enospray/workorder/enoch/:workOrderId/reject',
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          autoWeigh: {
            action: 'POST /enospray/workorder/finishautoweighing',
            loading: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          obsolete: {
            action: 'POST /enospray/workorder/obsolete',
            loading: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        computed: {
          status() {
            const { status = { code: '' } } = this.form.data
            switch (status.code!) {
              case 'SD':
                return 'painted'
              case 'C':
                return 'committed'
              case 'S':
                return 'settlemented'
              case 'D':
                return 'finish'
              case 'R':
                return 'return'
              default:
                return 'unpainted'
            }
          },
          disabled() {
            return this.form.data.status?.code === 'C' || this.form.data.status?.code === 'S' || this.form.data.status?.code === 'D'
          },
          standardPaints() {
            return this.form.data.paints?.filter((item) => item.status?.code === 'A' && item.colorLayer?.code === this.form.colorLayer.code) ?? []
          },
          obsoletPaints() {
            return this.form.data.paints?.filter((item) => item.status?.code === 'O') ?? []
          },
          warrantyApplicationSurfaces() {
            return (this.form.data.surfaces ?? []).filter((item) => item.jointWarrantyApplicationStatus?.code === 'P')
          },
          canIUpdateSurfaces() {
            return this.store.user.tenant?.id !== 'ENOCH' && this.form.data.surfaceModifying?.code !== 'A'
          },
          summary() {
            const { surfaces = [], paints = [], chargingStandard = { code: '' }, paintType = { code: '' } } = this.form.data
            return {
              count: surfaces.reduce((count, item) => calculator.add(count, calculator.mul(item.square, item.count)), 0),
              amount: surfaces.reduce(
                (amount, item) =>
                  calculator.add(amount, calculator.mul(item.count, item.surface?.chargingStandards[paintType?.code!][chargingStandard?.code!])),
                0
              ),
              weight: paints.reduce((weight, item) => calculator.add(weight, item.weight), 0),
              colorWeight: paints.reduce(
                (totalWeightColor, item) => calculator.add(totalWeightColor, item.colorLayer?.code === 'C' ? item.weight : 0),
                0
              ),
              pearlWeight: paints.reduce(
                (totalWeightColor, item) => calculator.add(totalWeightColor, item.colorLayer?.code === 'P' ? item.weight : 0),
                0
              )
            }
          }
        },
        children: {
          surfaces: {
            special: {
              add: {
                click() {
                  this.form.data.surfaces.push({ count: 1, square: 0, warrantied: { code: 'Y', message: '', type: '', description: '' } })
                }
              }
            },
            surface: {
              change(row: EnospraySprayDefinitions['WorkOrderSurfaceDto']) {
                const { paintType, chargingStandard } = this.form.data
                row.square = row.surface?.square
                row.price = row.surface?.chargingStandards[paintType?.code!][chargingStandard?.code!]
                // row.size = row.surface?.square
              }
            },
            size: {
              change(row: EnospraySprayDefinitions['WorkOrderSurfaceDto'], value: number) {
                row.count = calculator.div(value, row.surface?.square)
              }
            },
            operation: {
              delete: {
                disabled(row: EnospraySprayDefinitions['WorkOrderSurfaceDto']) {
                  return this.store.user.tenant?.id === 'ENOCH'
                    ? !this.store.accessRightsHash.WORK_ORDER_ENOCH_UPDATE
                    : Boolean(this.form.data.serviceId) ||
                        (row.id && ['painted'].includes(this.form.status)
                          ? this.form.canIUpdateSurfaces
                          : ['committed', 'settlemented', 'finish'].includes(this.form.status))
                },
                click(index: number) {
                  this.form.data.surfaces.splice(index, 1)
                }
              }
            }
          },
          workingTeam: {
            change() {
              this.form.data.assignees = []
              // this.form.assignees.data = this.form.data.workingTeam
            }
          },
          assignees: {
            data: []
          },
          colorLayer: {
            code: 'C'
          },
          paints: {
            active: { time: 0, data: null as EnospraySprayDefinitions['WorkOrderPaintDto'] | null },
            goods: {
              change(value: EnospraySprayDefinitions['WorkOrderPaintDto']) {}
            },
            add: {
              click() {
                if (
                  this.form.data.paints.filter((item) => item.status?.code === 'A' && item.colorLayer?.code === this.form.colorLayer.code && !item.id)
                    .length
                ) {
                  return
                }

                this.form.data.paints.push({
                  status: { code: 'A', message: '', type: '', description: '' },
                  colorLayer: { code: this.form.colorLayer.code, description: '', type: '', message: '' },
                  beforeAdjustingWeight: 0,
                  weight: 0
                })
              }
            },
            weight: {
              disabled() {
                return false
              }
            },
            delete: {
              click(index: number) {
                this.form.data.paints.splice(index, 1)
              }
            },
            afterAdjustingWeight: {
              change(row: EnospraySprayDefinitions['WorkOrderPaintDto'], value: number) {}
            },
            scope: {
              value: 'weight',
              click() {
                const { scope } = this.form.paints
                scope.value = scope.value === 'weight' ? 'adjustingWeight' : 'weight'
                this.form.paints.scope.change()
              },
              change() {
                this.form.paints.active.data = null

                const { scope } = this.form.paints

                this.form.data.paints.forEach((paint) => {
                  switch (scope.value) {
                    case 'weight':
                      // let increment = calculator.sub(paint.beforeAdjustingWeight, paint.afterAdjustingWeight, 2)
                      // if (paint.beforeAdjustingWeight && paint.afterAdjustingWeight && increment >= 0) {
                      //   paint.weight = calculator.add(paint.weight, increment)
                      //   paint.beforeAdjustingWeight = 0
                      //   paint.afterAdjustingWeight = 0
                      // }
                      break
                    case 'adjustingWeight':
                      break
                  }
                })
              }
            },
            sample: {
              value: false,
              index: 0,
              click() {}
            },
            scanner: {
              connection: null as IScannerConnection | null,
              code: '',
              active: false,
              async open() {
                this.form.paints.scanner.connection = getScannerConnection((event, instance) => {
                  switch (event.type) {
                    case ScannerEventType.CONFIG:
                      instance.serialPortRequestOptions = {
                        filters: [
                          { usbProductId: 0x090a, usbVendorId: 0x0c2e },
                          { usbProductId: 0x7523, usbVendorId: 0x1a86 },
                          { usbProductId: 0x096a, usbVendorId: 0x060e },
                          { usbProductId: 0x2303, usbVendorId: 0x067b }
                        ]
                      }
                      instance.serialOptions = {
                        baudRate: 9600,
                        dataBits: 8,
                        parity: 'none',
                        stopBits: 1
                      }
                      break
                    case ScannerEventType.CONNECTED:
                      EnMessage.success('扫码枪已连接')
                      this.form.paints.scanner.active = true
                      break
                    case ScannerEventType.DISCONNECTED:
                      EnMessage.info('扫码枪已断开')
                      this.form.paints.scanner.active = false
                      break
                    case ScannerEventType.MESSAGE:
                      const code = event.data?.code.trimEnd()

                      if (code) {
                        this.form.paints.scanner.code = code
                        if (scannerTimer) clearTimeout(scannerTimer)

                        scannerTimer = setTimeout(async () => {
                          const current = this.form.data.paints.find(
                            (item) =>
                              item.status?.code === 'A' && item.colorLayer?.code === this.form.colorLayer.code && item.goods?.barcodes.includes(code)
                          )

                          if (current) {
                            this.form.paints.active.data = current
                          } else {
                            const res = await this.ajax('GET /enospray/common/goods', {
                              payload: { goodsTypeCategoryCode: 'PAINT', paintBrandCode: this.form.data.paintType?.brand.code, barcode: code }
                            })

                            if (res.data.length) {
                              for (const item of res.data) {
                                item.workshopGoodsSpecification = {
                                  ...item.workshopGoodsSpecification,
                                  goods: item.storageGoodsSpecification?.goods
                                }
                              }

                              const init = {
                                status: { code: 'A', message: '', type: '', description: '' },
                                colorLayer: { code: this.form.colorLayer.code, description: '', type: '', message: '' },
                                beforeAdjustingWeight: 0,
                                weight: 0,
                                goods: res.data[0] as EnospraySprayDefinitions['SprayGoodsDto']
                              }
                              this.form.data.paints.push(init)
                              this.form.paints.active.data = init
                              this.form.paints.active.time = this.form.paints.scope.value === 'weight' ? 0 : 1
                            }
                          }
                        }, duration)
                      }

                      break
                  }
                })

                try {
                  await this.form.paints.scanner.connection.open()
                } catch (e) {
                  console.error(e)
                }
              },
              close() {
                this.form.paints.scanner.connection?.close()
              }
            },
            scale: {
              connection: null as IScaleConnection | null,
              code: '',
              active: false,
              async open() {
                this.form.paints.scanner.connection = getScaleConnection((event, instance) => {
                  switch (event.type) {
                    case ScaleEventType.CONFIG:
                      instance.serialPortRequestOptions = {
                        filters: [
                          { usbProductId: 0x6001, usbVendorId: 0x0403 },
                          { usbProductId: 0x2302, usbVendorId: 0x0eb8 }
                        ]
                      }

                      instance.serialOptions =
                        this.store.user.sprayTenant?.weigherBrand?.code === 'ST'
                          ? {
                              baudRate: 2400,
                              dataBits: 7,
                              parity: 'odd',
                              stopBits: 1
                            }
                          : {
                              baudRate: 9600,
                              dataBits: 8,
                              parity: 'none',
                              stopBits: 1
                            }
                      break
                    case ScaleEventType.CONNECTED:
                      EnMessage.success('电子秤已连接')
                      this.form.paints.scale.active = true
                      break
                    case ScaleEventType.DISCONNECTED:
                      EnMessage.info('电子秤已断开')
                      this.form.paints.scale.active = false
                      break
                    case ScaleEventType.MESSAGE:
                      const code = event.data?.code.trimEnd()
                      if (code) {
                        if (scaleTimer) clearTimeout(scaleTimer)
                        scaleTimer = setTimeout(async () => {}, 1500)
                      }

                      break
                  }
                })

                try {
                  await this.form.paints.scale.connection?.open()
                } catch (e) {
                  console.error(e)
                }
              },
              close() {
                this.form.paints.scale.connection?.close()
              }
            }
          }
        }
      },
      usage: {
        ajax: {
          get: {
            action: 'GET /enospray/common/goods/type/usage',
            data: 'array'
          }
        }
      },
      surface: {
        visible: false,
        data: [] as EnospraySprayDefinitions['LookupDto'][],
        selection: {
          change(items: EnospraySprayDefinitions['LookupDto'][]) {
            this.surface.data = items
          }
        },
        confirm() {
          for (const item of this.surface.data) {
            const exist = this.form.data.surfaces.find((s) => s.surface?.code === item.code)
            if (!exist) {
              this.form.data.surfaces.push({
                surface: item,
                square: item.square,
                price: item.chargingStandards[this.form.data.paintType?.code!][this.form.data.chargingStandard?.code!],
                count: 1,
                warrantied: { code: ['S', 'B'].includes(item.code.split('_')[1]) ? 'N' : 'Y', message: '', description: '', type: '' },
                jointWarrantyApplicationStatus: { code: 'NA', message: '', description: '', type: '' }
              })
            }
          }
        }
      },
      dialog: {
        visible: false
      },
      formula: {
        visible: false,
        filters: {} as Record<string, any>
      },
      warrantyApply: {
        visible: false
      },
      warrantyAudit: {
        visible: false
      },
      detail: {
        visible: false,
        success(id: number | undefined) {
          this.form.data.id ??= id
          this.form.get()
        }
      },
      procedure: {
        visible: false,
        change(type: string) {
          if (this.form.data.procedureType.code === type) return (this.procedure.visible = false)
          if (
            this.form.data.procedureType.code === '3' &&
            this.form.data.paints.filter((item) => item.status?.code === 'A' && item.colorLayer?.code === 'P').length
          ) {
            this.procedure.visible = false
            return EnMessage.warning('珍珠层存在色母，不可更换工序！')
          }
          this.form.data.procedureType = { code: type, message: '', type: '', description: '' }
          this.procedure.visible = false
        }
      },
      logs: {
        visible: false
      },
      merge: {
        visible: false
      }
    }
  },

  mounted() {
    this.usage.get()
    // this.form.data.id = 768483
    // this.form.get()
  },

  unmounted() {
    this.form.paints.scanner.close()
    this.form.paints.scale.close()
  }
})
</script>
